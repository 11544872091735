import { useTranslation } from "react-i18next";
import { useStore } from "../../store/store";
import useSignup from "../hooks/useSignup";
import Input from "./Input";
import SignupButton from "./SignupButton";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import SignupSucceded from "./SignupSucceded";
import { twMerge } from "tailwind-merge";
interface IProps {
	isJournalist: boolean;
}
const SignupForm = (props: IProps) => {
	const { t } = useTranslation();
	const { liveToken } = useParams();
	const name = useStore((state) => state.name);
	const setName = useStore((state) => state.setName);
	const lastName = useStore((state) => state.lastName);
	const setLastName = useStore((state) => state.setLastName);
	const email = useStore((state) => state.email);
	const setEmail = useStore((state) => state.setEmail);
	const [enterprise, setEnterprise] = useState("");
	const [registerSuccess, setRegisterSuccess] = useState(false);
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const liveId = useStore((state) => state.liveId);
	const signupMutation = useSignup();
	const client = useQueryClient();
	const navigate = useNavigate();
	const signup = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!name.trim() || !lastName.trim() || !email.trim() || !enterprise.trim()) {
			return toast.error(t("signup_page.fill_all_fields"));
		}
		if (!emailRegex.test(email)) {
			return toast.error(t("signup_page.invalid_email"));
		}
		signupMutation.mutate({
			firstName: name,
			lastName,
			email,
			isOnline: false,
			isRegistered: true,
			liveId,
			role: props.isJournalist ? "journalist" : "listener",
			enterprise,
		});
	};
	useEffect(() => {
		if (signupMutation.isSuccess) {
			client.invalidateQueries({ queryKey: ["liveDetails", liveToken] });
			const token = signupMutation.data?.data?.token;
			if (signupMutation.data?.data?.token) {
				navigate(`/live/${liveToken}?token=${token}`);
				window.location.reload();
			}
			setRegisterSuccess(true);
		}
		if (
			signupMutation.isError &&
			signupMutation.error &&
			signupMutation.error instanceof AxiosError
		) {
			setRegisterSuccess(false);
			toast.error(t("live_page.server_error"));
		}
	}, [
		signupMutation.isSuccess,
		signupMutation.isError,
		liveToken,
		signupMutation.error,
		client,
		email,
		signupMutation.data?.data?.token,
	]);

	return (
		<form
			className={twMerge(
				"h-96 max-h-[490px] w-full rounded-2xl bg-[#0C3949] px-6 py-3  text-white shadow-md  lg:h-[490px] lg:py-7 lg:text-xs  xl:w-[30rem] xl:text-base",
				props.isJournalist && "bg-text"
			)}
			onSubmit={signup}
			style={{
				boxShadow: "1px -2px 10px -1px rgba(255, 255, 255, 0.40)",
			}}
		>
			<Toaster
				toastOptions={{
					style: {
						textAlign: "center",
					},
				}}
			/>
			{registerSuccess ? (
				<SignupSucceded email={email} />
			) : (
				<div className="flex flex-col items-center justify-between gap-2">
					<h2 className=" p-1 text-xl font-medium lg:mb-5"> {t("signup_page.form_header")}</h2>
					<div className="flex w-full flex-row gap-4 ">
						<Input type="text" label={t("signup_page.last_name")} value={name} setValue={setName} />
						<Input
							type="text"
							label={t("signup_page.name")}
							value={lastName}
							setValue={setLastName}
						/>
					</div>
					<Input type="email" label={t("signup_page.email")} value={email} setValue={setEmail} />
					<Input
						type="text"
						label={t("signup_page.enterprise")}
						value={enterprise}
						setValue={setEnterprise}
					/>
					<div className="w-full lg:mt-3">
						<SignupButton
							width="w-full"
							loading={signupMutation.isLoading}
							text={t("signup_page.register")}
						/>
					</div>
				</div>
			)}
		</form>
	);
};

export default SignupForm;
