import { useTranslation } from "react-i18next";
import SocketIndicator from "./../../assets/SocketIndicator.png";
import ReloadIcon from "../../assets/ReloadIcon";

const WebSocketIndicator = () => {
	const { t } = useTranslation();
	return (
		<div className="aspect-video w-full flex-grow md:max-h-full md:w-auto">
			<div className="mx-auto block aspect-video w-full rounded-lg bg-rte  md:h-full md:w-auto">
				<div className="relative top-[5%] flex flex-col gap-4 lg:top-[10%]">
					<img src={SocketIndicator} alt="refresh" className="mx-auto block w-1/4 " />
					<div className="flex flex-col items-center gap-3 lg:gap-8">
						<h2
							className=" text-center  text-white"
							style={{
								fontSize: "clamp(0.75rem, 1vw, 1.25rem)",
							}}
						>
							{t("live_page.socket_error")}
						</h2>
						<button
							className="flex items-center gap-2 rounded-lg bg-white p-1  text-rte md:rounded-xl lg:px-3 lg:py-2"
							style={{
								fontSize: "clamp(0.75rem, .95vw, 1.25rem)",
							}}
							onClick={() => window.location.reload()}
						>
							<ReloadIcon />
							{t("live_page.reload")}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WebSocketIndicator;
