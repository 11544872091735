interface IProps {
	src: string | undefined;
}

const Iframe = (props: IProps) => {
	return (
		<div className="aspect-video w-full flex-grow md:max-h-full md:w-auto">
			<iframe
				// if videoEmbedUrl exists it means that the live is over and the video is embeded
				src={props.src}
				allowFullScreen
				className="mx-auto block aspect-video w-full rounded-lg border-0 outline-none md:h-full md:w-auto"
				title="Live"
				allow="autoplay;"
			></iframe>
		</div>
	);
};

export default Iframe;
