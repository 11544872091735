import { useState } from "react";
import ExportIcon from "../../../assets/ExportIcon";
import { useStore } from "../../../store/store";
import useExportQuestions from "../../../InteractionPage/hooks/useExportQuestions";
import { useTranslation } from "react-i18next";

const ExportQuestionsButton = () => {
	const [hovered, setHovered] = useState(false);
	const approvedQuestions = useStore((state) => state.approvedQuestions);
	const isCommenValiadation = useStore((state) => state.isCommentValidation);
	const questions = useStore((state) => state.questions);
	const exportQuestionQuery = useExportQuestions();
	const { t } = useTranslation();

	return (
		<button
			onMouseOver={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			disabled={isCommenValiadation ? questions?.length === 0 : approvedQuestions?.length === 0}
			onFocus={() => setHovered(true)}
			onClick={() => exportQuestionQuery.refetch()}
			className="group relative flex h-full w-16 items-center justify-center rounded-lg border border-rte bg-white p-2 transition-all disabled:cursor-not-allowed"
		>
			<span className="absolute -top-8 left-1/3 z-50 hidden whitespace-nowrap rounded-lg  bg-[#2B292980] p-2 text-sm text-white  transition-all group-hover:block">
				{t("live_page.export_questions")}
			</span>
			<ExportIcon hovered={hovered} />
		</button>
	);
};

export default ExportQuestionsButton;
