import { useStore } from "../../store/store";
import LiveStatusImage from "./../../assets/LiveStatusImage.svg";
import RegisterBackgroundImage from "./../../assets/RegisterBackground.png";
interface IProps {
	text?: string;
}

const LiveStatusIndicator = (props: IProps) => {
	const liveTitle = useStore((state) => state.liveTitle);
	const startDate = useStore((state) => state.startDate);
	const endDate = useStore((state) => state.endDate);
	return (
		<div className="aspect-video w-full flex-grow md:max-h-full md:w-auto">
			<div
				className="mx-auto block aspect-video w-full rounded-lg bg-rte  md:h-full md:w-auto"
				style={{
					backgroundImage: `url(${RegisterBackgroundImage})`,
				}}
			>
				<div className="relative top-[5%] flex flex-col gap-3 lg:top-[15%]">
					<img src={LiveStatusImage} alt="LiveStatusImage" className="m-auto w-1/3" />
					{props.text ? (
						<h2 className="text-center text-lg text-white sm:text-xl lg:text-2xl">{props.text}</h2>
					) : (
						<>
							<div className="group relative">
								<h2
									className=" text-center text-sm text-white xl:px-3"
									style={{
										fontSize: "clamp(0.75rem, 1vw, 1.25rem)",
									}}
								>
									{liveTitle}
								</h2>

								<h2 className="absolute left-1/2 z-10 hidden -translate-x-1/2 rounded bg-[#ffffffe7] p-2 text-center text-sm text-rte group-hover:block">
									{liveTitle}
								</h2>
							</div>
							<h2
								className="  text-center text-sm text-white sm:text-sm md:w-auto lg:text-lg"
								style={{
									fontSize: "clamp(0.75rem, 1vw, 1.25rem)",
								}}
							>
								<span title={startDate + endDate}>
									{startDate} {endDate}
								</span>
							</h2>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default LiveStatusIndicator;
