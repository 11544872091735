import { useEffect, useRef, useState } from "react";
import { useStore } from "../../../store/store";
import Question from "../Questions/Question/Question";
import BeforeLive from "../Questions/BeforeLive";
import BackArrow from "../../../assets/BackArrow.svg";
import SumbitEdit from "../../../assets/UpdateQuestion.svg";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import "./Moderator.css";
import useGetQuestions, { IQuestions } from "../../hooks/useGetQuestions";
import useGetLiveDetails from "../../../SignupPage/hooks/useGetLiveDetails";
import useEditQuestion from "../../hooks/useEditQuestion";
import { useParams } from "react-router-dom";
import useDeleteQuestion from "../../../InteractionPage/hooks/useDeleteQuestion";
import QuestionActions from "../Questions/Question/QuestionActions";
import useManageQuestion from "../../hooks/useManageQuestion";
import { useTranslation } from "react-i18next";
import useGetQuestion from "../../hooks/useGetQuestion";
import { SocketEvents } from "../../hooks/useSocket/types";
import Loader from "../../../components/Loader/Loader";
import SmallLoader from "../../../components/SmallLoader";
import FilterIcon from "../../../assets/FilterIcon";
import TrierIcon from "../../../assets/TrierIcon";
import Filter from "../Questions/QuestionsFilter/Filter";
import FilterManagmentIcon from "../../../assets/FIlterManagmentIcon";

interface IProps {
	liveId: number | undefined;
}
const Moderator = (props: IProps) => {
	const { t } = useTranslation();
	const questionsQuery = useGetQuestions(props.liveId);
	const questions = useStore((state) => state.questions);
	const questionsRef = useRef<VirtuosoHandle>(null);
	const questionToView = useStore((state) => state.questionToView);
	const setQuestionToView = useStore((state) => state.setQuestionToView);
	const questionQuery = useGetQuestion(questionToView?.liveId, questionToView?.id as number);
	const socket = useStore((state) => state.socket);
	const [questionsList, setQuestionsList] = useState(questions);
	const answers = useStore((state) => state.allAnswers);
	const [answersList, setAnswersList] = useState<IQuestions[]>([]);
	const setAnswers = useStore((state) => state.setAllAnswers);
	const { liveToken } = useParams();
	const liveDetails = useGetLiveDetails(liveToken);
	const [questionToUpdate, setQuestionToUpdate] = useState({} as IQuestions);
	const editQuestionMutation = useEditQuestion(questionToUpdate?.id);
	const deleteQuestionMutation = useDeleteQuestion(questionToUpdate?.id);
	const manageQuestionMutation = useManageQuestion(questionToUpdate?.id);
	const [currentAction, setCurrentAction] = useState("");
	const [editedText, setEditedText] = useState("");
	const [editedAnswerText, setEditedAnswerText] = useState("");
	const [searchText, setSearchText] = useState("");
	const [answerSearchText, setAnswerSearchText] = useState("");
	const [filterQuestion, setFilterQuestion] = useState(false);
	const [filterAnswer, setFilterAnswer] = useState(false);
	const [atBottom, setAtBottom] = useState(false);
	const [moreQuestions, setMoreQuestions] = useState(false);
	const [activeItem, setActiveItem] = useState("filter");
	const approvedQuestions = useStore((state) => state.approvedQuestions);
	const questionsFilter = useStore((state) => state.questionsFilter);
	const setQuestionsFilter = useStore((state) => state.setQuestionsFilter);
	const sortedApprovedQuestions = approvedQuestions.sort((a, b) => {
		switch (questionsFilter) {
			case "recent":
				return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
			case "oldest":
				return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
			case "likes":
				return b.likes - a.likes;
		}
	});
	const resetFilters = () => {
		if (activeItem !== "filter") {
			setQuestionsFilter("oldest");
		}
	};
	const handleClick = (item: string) => {
		setActiveItem(item);
	};

	useEffect(() => {
		if (answersList.length === 0) {
			setAnswersList(answers);
		}
	}, [answers]);

	const options = [
		{
			id: 1,
			text: t("moderator_page.all_questions"),
		},
		{
			id: 2,
			text: t("moderator_page.questions_managed_by"),
		},
		{
			id: 3,
			text: t("moderator_page.questions_not_managed_by"),
		},
		{
			id: 4,
			text: t("moderator_page.questions_approved"),
		},
		{
			id: 5,
			text: t("moderator_page.questions_not_approved"),
		},
	];
	const answersOptions = [
		{
			id: 1,
			text: t("moderator_page.all_answers"),
		},
		{
			id: 2,
			text: t("moderator_page.answers_managed_by"),
		},
		{
			id: 3,
			text: t("moderator_page.answers_not_managed_by"),
		},
		{
			id: 4,
			text: t("moderator_page.answers_approved"),
		},
		{
			id: 5,
			text: t("moderator_page.answers_not_approved"),
		},
	];

	const [selectedFilter, setSelectedFilter] = useState(options[0]);
	const [selectedAnswerFilter, setSelectedAnswerFilter] = useState(answersOptions[0]);

	useEffect(() => {
		if (!atBottom && questionsList.length > 0) {
			setMoreQuestions(true);
		} else {
			setMoreQuestions(false);
		}
	}, [questionsList.length, atBottom]);

	useEffect(() => {
		switch (selectedFilter?.id) {
			case 1:
				if (searchText === "") {
					setQuestionsList(questions);
				} else {
					setQuestionsList(questions.filter((q) => containsSearchWord(q, searchText)));
				}
				break;
			case 2:
				if (searchText === "") {
					setQuestionsList(questions.filter((q) => q.managedById));
				} else {
					setQuestionsList(
						questions.filter((q) => q.managedById && containsSearchWord(q, searchText))
					);
				}
				break;
			case 3:
				if (searchText === "") {
					setQuestionsList(questions.filter((q) => !q.managedById));
				} else {
					setQuestionsList(
						questions.filter((q) => !q.managedById && containsSearchWord(q, searchText))
					);
				}
				break;
			case 4:
				if (searchText === "") {
					setQuestionsList(questions.filter((q) => q.approved));
				} else {
					setQuestionsList(
						questions.filter((q) => q.approved && containsSearchWord(q, searchText))
					);
				}
				break;
			case 5:
				if (searchText === "") {
					setQuestionsList(questions.filter((q) => !q.approved));
				} else {
					setQuestionsList(
						questions.filter((q) => !q.approved && containsSearchWord(q, searchText))
					);
				}
				break;
			default:
				if (searchText === "") {
					setQuestionsList(questions);
				} else {
					setQuestionsList(questions.filter((q) => containsSearchWord(q, searchText)));
				}
		}
	}, [selectedFilter, questions, searchText]);

	useEffect(() => {
		switch (selectedAnswerFilter?.id) {
			case 1:
				if (answerSearchText === "") {
					setAnswersList(answers);
				} else {
					setAnswersList(answers.filter((q) => containsSearchWord(q, answerSearchText)));
				}
				break;
			case 2:
				if (answerSearchText === "") {
					setAnswersList(answers.filter((a) => a.managedById));
				} else {
					setAnswersList(
						answers.filter((a) => a.managedById && containsSearchWord(a, answerSearchText))
					);
				}
				break;
			case 3:
				if (answerSearchText === "") {
					setAnswersList(answers.filter((a) => !a.managedById));
				} else {
					setAnswersList(
						answers.filter((a) => !a.managedById && containsSearchWord(a, answerSearchText))
					);
				}
				break;
			case 4:
				if (answerSearchText === "") {
					setAnswersList(answers.filter((a) => a.approved));
				} else {
					setAnswersList(
						answers.filter((a) => a.approved && containsSearchWord(a, answerSearchText))
					);
				}
				break;
			case 5:
				if (answerSearchText === "") {
					setAnswersList(answers.filter((a) => !a.approved));
				} else {
					setAnswersList(
						answers.filter((a) => !a.approved && containsSearchWord(a, answerSearchText))
					);
				}
				break;
			default:
				if (answerSearchText === "") {
					setAnswersList(questions);
				} else {
					setAnswersList(answers.filter((q) => containsSearchWord(q, answerSearchText)));
				}
		}
	}, [selectedAnswerFilter, answers, answerSearchText]);

	useEffect(() => {
		if (
			editQuestionMutation.isSuccess &&
			currentAction &&
			(currentAction === SocketEvents.editQuestion ||
				currentAction === SocketEvents.approveQuestion)
		) {
			if (socket?.readyState === WebSocket.OPEN) {
				socket?.send(
					JSON.stringify({
						type: currentAction,
						data: editQuestionMutation.data.data.question,
					})
				);
			}
		}
	}, [
		editQuestionMutation.isSuccess,
		editQuestionMutation.data?.data.question,
		currentAction,
		questionToUpdate,
	]);

	useEffect(() => {
		if (questionQuery.data) {
			const answers = questionQuery.data.filter((q) => {
				return q.type === "answer";
			});
			setAnswers(answers);
		}
	}, [questionQuery.data?.length]);

	if (liveDetails.isLoading) return null;

	const handleEdit = () => {
		editQuestionMutation.mutate({
			question: { question: editedText },
		});
		setEditedText("");
	};
	const handleAnswerEdit = () => {
		editQuestionMutation.mutate({
			question: { question: editedAnswerText },
		});
		setEditedAnswerText("");
	};

	const containsSearchWord = (
		value: string | IQuestions | number | null,
		searchWord: string
	): boolean => {
		if (typeof value === "string") {
			return value.toLowerCase().includes(searchWord.toLowerCase());
		} else if (value && typeof value === "number") {
			return value.toString().toLowerCase().includes(searchWord.toLowerCase());
		} else if (value && typeof value === "object") {
			return Object.values(value).some((innerValue) => containsSearchWord(innerValue, searchWord));
		}
		return false;
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(e.target.value);
	};
	const handleAnswerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAnswerSearchText(e.target.value);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleEdit();
		}
	};
	const handleAnswerKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleAnswerEdit();
		}
	};
	const handleEditQuestion = (question: IQuestions, type: string) => {
		setCurrentAction(type);
		setQuestionToUpdate(question);
		switch (type) {
			case "approveQuestion":
				editQuestionMutation.mutate({
					question: { approved: !question.approved },
				});
				break;
			case "editQuestion":
				if (question.type === "question") {
					setEditedText(questions.find((q) => q.id === question.id)?.question as string);
				} else {
					setEditedAnswerText(answers.find((q) => q.id === question.id)?.question as string);
				}
				break;
			case "deleteQuestion":
				deleteQuestionMutation.mutate();
				break;
			case "manageQuestion":
				manageQuestionMutation.mutate();
				break;
			case SocketEvents.broadcastQuestion:
				socket?.send(
					JSON.stringify({
						type: SocketEvents.broadcastQuestion,
						data: question,
					})
				);

				break;
			default:
				break;
		}
	};
	const questionsComponent = (
		<Virtuoso
			ref={questionsRef}
			className="h-full"
			data={activeItem === "filter" ? questionsList : sortedApprovedQuestions}
			totalCount={questionsList.length}
			initialTopMostItemIndex={questionsList.length - 1}
			atBottomStateChange={(bottom) => {
				setAtBottom(bottom);
			}}
			itemContent={(_, question) => {
				return (
					<Question
						subscriberId={question?.subscriber ? question?.subscriberId : question?.moderatorId}
						userId={0}
						key={question.id}
					>
						<Question.Sender
							createdAt={question.createdAt}
							lastName={
								question.subscriber
									? question?.subscriber?.liveSubscribers &&
										question?.subscriber?.liveSubscribers?.length === 1
										? question?.subscriber?.liveSubscribers[0]?.lastName
										: question?.subscriber?.lastName
									: question?.moderator?.username
							}
							subscriberId={question?.subscriber ? question?.subscriberId : question?.moderatorId}
							email={
								question?.subscriber ? question?.subscriber?.email : question?.moderator?.email
							}
							userId={0}
							type="question"
						/>
						<Question.Content
							questionId={question.id}
							question={question.question}
							subscriberId={question?.subscriber ? question?.subscriberId : question?.moderatorId}
							likes={question.likes}
							userId={0}
						>
							{(editQuestionMutation.isLoading ||
								deleteQuestionMutation.isLoading ||
								manageQuestionMutation.isLoading) &&
							questionToUpdate.id === question.id ? (
								<SmallLoader color="#00A6D9" />
							) : (
								<QuestionActions question={question} editQuestion={handleEditQuestion} />
							)}
						</Question.Content>
					</Question>
				);
			}}
		/>
	);

	const questionWithAnswerComponent = questionToView ? (
		<Virtuoso
			data={answersList?.filter((q: IQuestions) => q.questionId === questionToView?.id)}
			className="h-full"
			totalCount={
				answersList?.filter((q: IQuestions) => q.questionId === questionToView?.id)?.length
			}
			itemContent={(_, question) => {
				return (
					<Question
						subscriberId={question?.subscriber ? question?.subscriberId : question?.moderatorId}
						userId={0}
						key={question.id}
					>
						<Question.Sender
							createdAt={question.createdAt}
							subscriberId={question?.subscriber ? question?.subscriberId : question?.moderatorId}
							userId={0}
							type="question"
							email={question?.subscriber ? question?.subscriber?.email : question?.moderator.email}
							lastName={
								question.subscriber
									? question?.subscriber?.liveSubscribers &&
										question?.subscriber?.liveSubscribers?.length === 1
										? question?.subscriber?.liveSubscribers[0]?.lastName
										: question?.subscriber?.lastName
									: question?.moderator?.username
							}
							isAnswer={true}
						/>
						<Question.Content
							question={question.question}
							subscriberId={question?.subscriber ? question?.subscriberId : question?.moderatorId}
							userId={0}
							questionId={question.id}
							questionToBeAnswered={true}
							isAnswer={true}
							likes={question.likes}
						>
							{(editQuestionMutation.isLoading ||
								deleteQuestionMutation.isLoading ||
								manageQuestionMutation.isLoading) &&
							questionToUpdate.id === question.id ? (
								<SmallLoader color="#00A6D9" />
							) : (
								<QuestionActions question={question} editQuestion={handleEditQuestion} />
							)}
						</Question.Content>
					</Question>
				);
			}}
		/>
	) : null;

	return (
		<>
			{questionsQuery.isLoading ? (
				<Loader />
			) : questions?.length === 0 ? (
				<BeforeLive />
			) : !questionToView ? (
				<div className="h-full overflow-hidden">
					<div className="grid grid-cols-12 gap-2 px-4 py-2" style={{ height: "8%" }}>
						<div className="question-search col-span-6 sm:col-span-8 md:col-span-10">
							<input
								type="text"
								value={searchText}
								onChange={handleSearch}
								placeholder={t("moderator_page.search_question")}
							/>
						</div>
						<div className="col-span-6 sm:col-span-4 md:col-span-2">
							<button className="filter-button" onClick={() => setFilterQuestion(!filterQuestion)}>
								<span>
									<FilterManagmentIcon ClassName="iconFilter" />
									{t("moderator_page.Managment_questions")}
								</span>
							</button>
							{filterQuestion ? (
								<div className="filter-content">
									<div className="seperateFilter">
										<div
											className={`item ${activeItem === "filter" ? "active" : ""}`}
											onClick={() => handleClick("filter")}
										>
											<FilterIcon ClassName="iconFilter" /> {t("moderator_page.Filter_with")}
										</div>
										<div className="separator"></div>
										<div
											className={`item ${activeItem === "sort" ? "active" : ""}`}
											onClick={() => handleClick("sort")}
										>
											<TrierIcon ClassName="iconFilter" /> {t("moderator_page.sort_With")}
										</div>
									</div>

									<div className="filter-options p-3">
										{activeItem === "filter" ? (
											options.map((option) => (
												<button
													onClick={() => setSelectedFilter(option)}
													className="grid grid-cols-12"
													key={option.id}
												>
													<span
														className={`filter-option col-span-2 h-6 w-6 ${
															option.id === selectedFilter?.id ? "checked-option" : ""
														}`}
													></span>
													<div
														className={`filter-text col-span-10 ${
															option.id === selectedFilter?.id ? "checked-text" : ""
														}`}
													>
														{option.text}
													</div>
												</button>
											))
										) : (
											<>
												<Filter text={t("live_page.oldest_questions")} value="oldest" />
												<Filter text={t("live_page.recent_questions")} value="recent" />
												<Filter text={t("live_page.most_liked_questions")} value="likes" />
											</>
										)}
									</div>

									<div className="reset-container">
										<button
											className="reset-button"
											onClick={() => {
												setSelectedFilter(options[0]);
												setFilterQuestion(false);
												resetFilters();
											}}
										>
											{t("moderator_page.reset_filter_question")}
										</button>
									</div>
								</div>
							) : null}
						</div>
					</div>
					<div
						className={` o flex-grow overflow-hidden p-2`}
						style={{ height: editedText ? "80%" : "88%" }}
					>
						{questionsComponent}
						{moreQuestions && (
							<div className="sticky  bottom-1  flex w-full items-center  justify-center transition-all">
								<button
									onClick={() => {
										questionsRef.current?.scrollToIndex({
											index: questions.length - 1,
											behavior: "smooth",
										});

										setMoreQuestions(false);
									}}
									className=" cursor-pointer rounded-full bg-rte p-2 text-white"
								>
									{t("live_page.scroll_to_bottom")}
								</button>
							</div>
						)}
					</div>
					<div
						className="edit-question grid w-full grid-cols-12 px-4 py-2"
						style={{ height: "12%", display: editedText ? "grid" : "none" }}
					>
						<textarea
							className="col-span-11 w-full"
							placeholder="Modifier une question ..."
							value={editedText}
							onChange={(e) => setEditedText(e.target.value)}
							onKeyDown={(e) => handleKeyDown(e)}
						/>
						<button className="col-span-1 flex items-center justify-center" onClick={handleEdit}>
							<img src={SumbitEdit} alt="submit_edit" />
						</button>
					</div>
				</div>
			) : (
				<div className="h-full overflow-hidden">
					<div style={{ height: "12%", minHeight: "auto" }}>
						<div className="grid grid-cols-12 gap-2 px-4 py-2">
							<div className="question-search col-span-6 sm:col-span-8 md:col-span-10">
								<input
									type="text"
									value={answerSearchText}
									onChange={handleAnswerSearch}
									placeholder={t("moderator_page.search_answer")}
								/>
							</div>
							<div className="col-span-6 sm:col-span-4 md:col-span-2">
								<button className="filter-button" onClick={() => setFilterAnswer(!filterAnswer)}>
									<span>{selectedAnswerFilter?.text}</span>
								</button>
								{filterAnswer ? (
									<div className="filter-content">
										<div className="filter-title">{t("moderator_page.filter_answers")}</div>
										<div className="filter-options p-3">
											{answersOptions.map((option) => (
												<button
													onClick={() => setSelectedAnswerFilter(option)}
													className="grid grid-cols-12"
													key={option.id}
												>
													<span
														className={`filter-option col-span-2 h-6 w-6 ${
															option.id === selectedAnswerFilter?.id ? "checked-option" : ""
														}`}
													></span>
													<div
														className={`filter-text col-span-10 ${
															option.id === selectedAnswerFilter?.id ? "checked-text" : ""
														}`}
													>
														{option.text}
													</div>
												</button>
											))}
										</div>
										<div className="reset-container">
											<button
												className="reset-button"
												onClick={() => {
													setSelectedAnswerFilter(options[0]);
													setFilterAnswer(false);
												}}
											>
												{t("moderator_page.reset_filter_question")}
											</button>
										</div>
									</div>
								) : null}
							</div>
						</div>
						<div className="flex items-center gap-2 p-2">
							<div className="flex w-[1.5em] items-center justify-center">
								<button
									onClick={() => {
										setQuestionToView(null);
									}}
								>
									<img
										src={BackArrow}
										alt="Back arrow"
										className="cursor-pointer rounded bg-rte p-1"
									/>
								</button>
							</div>
							<div className="w-[98%] overflow-hidden text-ellipsis whitespace-nowrap">
								{questionToView?.question}
							</div>
						</div>
					</div>

					<div
						className={` flex-grow overflow-hidden p-2`}
						style={{ height: editedAnswerText ? "76%" : "84%" }}
					>
						{questionWithAnswerComponent}
					</div>
					<div
						className="edit-question grid w-full  grid-cols-12 px-4 py-2"
						style={{ height: "12%", display: editedAnswerText ? "grid" : "none" }}
					>
						<textarea
							className="col-span-11 w-full"
							placeholder="Modifier une question ..."
							value={editedAnswerText}
							onChange={(e) => setEditedAnswerText(e.target.value)}
							onKeyDown={handleAnswerKeyDown}
						/>
						<button
							className="col-span-1 flex items-center justify-center"
							onClick={handleAnswerEdit}
						>
							<img src={SumbitEdit} alt="submit_edit" />
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default Moderator;
