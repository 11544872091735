import { StateCreator } from "zustand";

import toast from "react-hot-toast";
import { IMessage } from "../hooks/messages/types";
import { ILivePageSlice } from "./types";

export const livePageSlice: StateCreator<ILivePageSlice> = (set) => ({
	activeTab: 2,
	setActiveTab: (tile) =>
		set(() => {
			if (tile === 1) {
				return {
					activeTab: tile,
					questionToEditId: null,
					messagesNotifications: 0,
					messageToReply: null,
				};
			}
			if (tile === 2) {
				return {
					activeTab: tile,
					questionToEditId: null,
					approvedQuestionsNotifications: 0,
					messageToReply: null,
				};
			}
			if (tile === 5) {
				return {
					activeTab: tile,
					questionToEditId: null,
					questionsNotifications: 0,
					messageToReply: null,
				};
			}
			if (tile === 7) {
				return {
					activeTab: tile,
					questionToEditId: null,
					backstageMessagesNotifications: 0,
					messageToReply: null,
				};
			}
			return { activeTab: tile, questionToEditId: null, messageToReply: null };
		}),
	showSidebar: true,
	showSidebarToggle: () => set((state) => ({ showSidebar: !state.showSidebar })),
	sideBarInput: "",
	setSideBarInput: (sideBarInput) => set({ sideBarInput }),
	description: "",
	setDescription: (description) => set({ description }),
	status: 0,
	setStatus: (status) => set({ status: status }),
	liveActive: 0,
	setLiveActive: (liveActive) => set({ liveActive }),
	videoEmbedUrl: "",
	setVideoEmbedUrl: (videoEmbedUrl) => set({ videoEmbedUrl }),
	socket: null,
	setWebSocket: (socket) => set({ socket }),
	webSocketError: false,
	setWebSocketError: (webSocketError) => set({ webSocketError }),
	questions: [],
	setQuestions: (questions) => set({ questions }),
	addQuestions: (question) =>
		set((state) => ({
			questions: [...state.questions, question],
			...(state.activeTab !== 5 && { questionsNotifications: state.questionsNotifications + 1 }),
		})),
	deleteQuestion: (question) => {
		set((state) => {
			const updatedQuestions = [...state.approvedQuestions];
			const existingQuestion = updatedQuestions.findIndex((questionToFind) => {
				return questionToFind.id === question.id;
			});
			if (existingQuestion > -1) {
				updatedQuestions.splice(existingQuestion, 1);
			}
			return {
				approvedQuestions: updatedQuestions,
			};
		});
	},
	editQuestion: (question) => {
		set((state) => {
			if (question.type === "answer") {
				const updatedApprovedAnswers = [...state.approvedAnswers];
				const existingQuestion = updatedApprovedAnswers.findIndex((questionToFind) => {
					return questionToFind.id === question.id;
				});
				if (existingQuestion > -1) {
					updatedApprovedAnswers[existingQuestion].question = question.question;
				}
				const updatedAnswers = [...state.allAnswers];
				const existingAnswer = updatedAnswers.findIndex((answerToFind) => {
					return answerToFind.id === question.id;
				});
				if (existingAnswer > -1) {
					updatedAnswers[existingAnswer].question = question.question;
				}
				return {
					approvedAnswers: updatedApprovedAnswers,
					allAnswers: updatedAnswers,
				};
			}
			const updatedQuestions = [...state.questions];
			const updatedApprovedQuestions = [...state.approvedQuestions];
			const existingQuestion = updatedQuestions.findIndex((questionToFind) => {
				return questionToFind.id === question.id;
			});
			if (existingQuestion > -1) {
				updatedQuestions[existingQuestion].question = question.question;
			}

			const existingApprovedQuestion = updatedApprovedQuestions.findIndex((questionToFind) => {
				return questionToFind.id === question.id;
			});
			if (existingApprovedQuestion > -1) {
				updatedApprovedQuestions[existingApprovedQuestion].question = question.question;
			}
			return {
				questions: updatedQuestions,
				approvedQuestions: updatedApprovedQuestions,
			};
		});
	},
	approvedQuestions: [],
	setApprovedQuestions: (questions) => set({ approvedQuestions: questions }),
	addApprovedQuestions: (question) =>
		set((state) => {
			// if the question approved is of type answer we need to update the answers array as well as the approvedQuestions's answers array
			if (question.type === "answer") {
				const updatedAnswers = [...state.allAnswers];
				const updatedApprovedAnswers = [...state.approvedAnswers];
				const updatedApprovedQuestions = [...state.approvedQuestions];

				const foundApprovedAnswer = updatedApprovedAnswers.findIndex((questionToFind) => {
					return questionToFind.id === question.id;
				});
				const foundAnswer = updatedAnswers.findIndex((questionToFind) => {
					return questionToFind.id === question.id;
				});
				if (foundAnswer > -1) {
					updatedAnswers[foundAnswer].approved = question.approved;
				}

				updatedApprovedAnswers.push(question);
				if (foundApprovedAnswer > -1 && question.approved) {
					updatedApprovedAnswers.splice(foundApprovedAnswer, 1);
				} else if (foundApprovedAnswer > -1 && !question.approved) {
					updatedApprovedAnswers.splice(foundApprovedAnswer, 1);
				}

				const existingApprovedQuestion = updatedApprovedQuestions.findIndex((questionToFind) => {
					return questionToFind.id === question.questionId;
				});
				if (existingApprovedQuestion > -1 && question.approved) {
					updatedApprovedQuestions[existingApprovedQuestion].answers.push(question);
				} else if (existingApprovedQuestion > -1 && !question.approved) {
					updatedApprovedQuestions[existingApprovedQuestion].answers = updatedApprovedQuestions[
						existingApprovedQuestion
					].answers.filter((q) => q.id !== question.id);
				}
				return {
					approvedQuestions: updatedApprovedQuestions,
					...(state.questionToAnswer === question.questionId && {
						approvedAnswers: updatedApprovedAnswers,
					}),
					allAnswers: updatedAnswers,
				};
			} else {
				// if the question approved is a question we need to update the questions array as well as the approvedQuestions array
				const updatedApprovedQuestions = [...state.approvedQuestions];
				const existingQuestion = updatedApprovedQuestions.findIndex((questionToFind) => {
					return questionToFind.id === question.id;
				});
				if (existingQuestion > -1) {
					updatedApprovedQuestions.splice(existingQuestion, 1);
				}
				updatedApprovedQuestions.push(question);
				const updatedQuestions = [...state.questions];
				const foundQuestion = updatedQuestions.findIndex((questionToFind) => {
					return questionToFind.id === question.id;
				});
				if (foundQuestion > -1) {
					updatedQuestions[foundQuestion].approved = question.approved;
				}
				return {
					approvedQuestions: updatedApprovedQuestions,
					...(state.approvedQuestionsNotifications >= 0 &&
						state.activeTab !== 2 &&
						question.approved && {
							approvedQuestionsNotifications: state.approvedQuestionsNotifications + 1,
						}),
					...(state.approvedQuestionsNotifications > 0 &&
						state.activeTab !== 2 &&
						!question.approved && {
							approvedQuestionsNotifications: state.approvedQuestionsNotifications - 1,
						}),
					questions: updatedQuestions,
				};
			}
		}),
	questionToAnswer: null,
	questionToView: null,
	deleteQuestionByModerator: (question) =>
		set((state) => {
			if (question.type === "answer") {
				const updatedApprovedQuestions = [...state.approvedQuestions];
				const updatedQuestions = [...state.questions];
				const questionAnsweredIndex = updatedApprovedQuestions.findIndex(
					(q) => q.id === question.questionId
				);
				if (questionAnsweredIndex > -1) {
					updatedApprovedQuestions[questionAnsweredIndex].answers = updatedApprovedQuestions[
						questionAnsweredIndex
					].answers.filter((answer) => answer.id !== question.id);
				}
				const answerQuestionIndex = updatedQuestions.findIndex((q) => q.id === question.questionId);
				if (answerQuestionIndex > -1) {
					updatedQuestions[answerQuestionIndex].answers = updatedQuestions[
						answerQuestionIndex
					].answers.filter((answer) => answer.id !== question.id);
				}
				const updatedApprovedAnswers = state.approvedAnswers.filter((q) => q.id !== question.id);
				const updatedAnswers = state.allAnswers.filter((q) => q.id !== question.id);
				return {
					approvedAnswers: updatedApprovedAnswers,
					allAnswers: updatedAnswers,
					approvedQuestions: updatedApprovedQuestions,
					questions: updatedQuestions,
				};
			}
			if (question.id === state.questionToView?.id || question.id === state.questionToAnswer) {
				toast.error("La question que vous êtes en train de consulter a été supprimée.");
			}
			const updatedQuestions = [...state.questions];
			const updatedApprovedQuestions = [...state.approvedQuestions];
			return {
				questions: updatedQuestions.filter((q) => q.id !== question.id),
				approvedQuestions: updatedApprovedQuestions.filter((q) => q.id !== question.id),
				...(state.approvedQuestionsNotifications > 0 &&
					state.activeTab !== 2 && {
						approvedQuestionsNotifications: state.approvedQuestionsNotifications - 1,
					}),
				...(state.questionsNotifications > 0 &&
					state.activeTab !== 5 && {
						questionsNotifications: state.questionsNotifications - 1,
					}),
				...(question.id === state.questionToView?.id && { questionToView: null }),
				...(question.id === state.questionToAnswer && { questionToAnswer: null, activeTab: 2 }),
			};
		}),
	addQuestionLike: (questionLikeEvent) =>
		set((state) => {
			const updatedQuestions = [...state.approvedQuestions];
			if (questionLikeEvent.data.result === "like added") {
				const questionToFindIndex = updatedQuestions.findIndex((question) => {
					return question.id === questionLikeEvent.questionId;
				});
				if (questionToFindIndex > -1) {
					updatedQuestions[questionToFindIndex].likes =
						updatedQuestions[questionToFindIndex]?.likes + 1;
					if (!updatedQuestions[questionToFindIndex].questionLikes) {
						updatedQuestions[questionToFindIndex].questionLikes = [];
					}

					updatedQuestions[questionToFindIndex].questionLikes.push(
						questionLikeEvent.data.questionLike!
					);
				}
			} else if (questionLikeEvent.data.result === "like deleted") {
				const questionToFindIndex = updatedQuestions.findIndex((question) => {
					return question.id === questionLikeEvent.questionId;
				});
				if (questionToFindIndex > -1) {
					updatedQuestions[questionToFindIndex].likes =
						updatedQuestions[questionToFindIndex]?.likes - 1;
					updatedQuestions[questionToFindIndex].questionLikes = updatedQuestions[
						questionToFindIndex
					].questionLikes.filter((like) => {
						return like.questionId !== questionLikeEvent.questionId;
					});
				}
			}
			return {
				approvedQuestions: updatedQuestions,
			};
		}),
	manageQuestion: (question) =>
		set((state) => {
			const updatedQuestions = [...state.questions];
			const updatedAnswers = [...state.allAnswers];
			const foundAnswer = updatedAnswers.findIndex((questionToFind) => {
				return questionToFind.id === question.id;
			});
			if (foundAnswer > -1) {
				updatedAnswers[foundAnswer].managedById = question.managedById;
				updatedAnswers[foundAnswer].managedBy = question.managedBy;
			}
			const foundQuestion = updatedQuestions.findIndex((questionToFind) => {
				return questionToFind.id === question.id;
			});
			if (foundQuestion > -1) {
				updatedQuestions[foundQuestion].managedById = question.managedById;
				updatedQuestions[foundQuestion].managedBy = question.managedBy;
			}
			return {
				questions: updatedQuestions,
				...(question.type === "answer" && {
					allAnswers: updatedAnswers,
				}),
			};
		}),

	setQuestionToAnswer: (question) => set({ questionToAnswer: question }),
	setQuestionToView: (question) => set({ questionToView: question }),
	allAnswers: [],
	setAllAnswers: (answers) => set({ allAnswers: answers }),
	approvedAnswers: [],
	setApprovedAnswers: (approvedAnswers) => set({ approvedAnswers }),
	addAnswers: (answer) =>
		set((state) => {
			if (answer.approved) {
				const updatedApprovedQuestions = [...state.approvedQuestions];
				const existingQuestion = updatedApprovedQuestions.findIndex((questionToFind) => {
					return questionToFind.id === answer.questionId;
				});
				if (existingQuestion > -1) {
					if (updatedApprovedQuestions[existingQuestion]?.answers?.length) {
						updatedApprovedQuestions[existingQuestion].answers.push(answer);
					} else {
						updatedApprovedQuestions[existingQuestion].answers = [answer];
					}
				}
				return {
					allAnswers: [...state.allAnswers, answer],
					approvedAnswers: [...state.approvedAnswers, answer],
					approvedQuestions: updatedApprovedQuestions,
				};
			} else {
				const updatedQuestions = [...state.questions];
				const existingQuestion = updatedQuestions.findIndex((questionToFind) => {
					return questionToFind.id === answer.questionId;
				});
				if (existingQuestion > -1) {
					if (updatedQuestions[existingQuestion]?.answers?.length) {
						updatedQuestions[existingQuestion].answers.push(answer);
					} else {
						updatedQuestions[existingQuestion].answers = [answer];
					}
				}
				return {
					approvedAnswers: [...state.approvedAnswers, answer],
					allAnswers: [...state.allAnswers, answer],
					questions: updatedQuestions,
				};
			}
		}),
	messages: [],
	backstageMessages: [],
	setMessages: (messages) =>
		set((state) => {
			return { messages: [...new Set([...state.messages, ...messages])] };
		}),
	setBackstageMessages: (messages) =>
		set((state) => {
			return {
				backstageMessages: [...new Set([...state.backstageMessages, ...messages])],
			};
		}),
	page: 1,
	backstagePage: 1,
	setNextPage: () => set((state) => ({ page: state.page + 1 })),
	setBackstageNextPage: () => set((state) => ({ backstagePage: state.backstagePage + 1 })),
	addMessages: (message) =>
		set((state) => {
			const newMessage = {
				repliedTo: message.repliedTo,
				id: message.id,
				createdAt: message.createdAt,
				liveId: message.liveId,
				message: message.message,
				type: message.type,
				...(message.subscriber && {
					subscriber: {
						id: message.subscriber.id,
						firstName: message.subscriber.firstName,
						lastName: message.subscriber.lastName,
					},
				}),
				...(message?.moderator && {
					moderator: message?.moderator,
				}),
			} as IMessage;
			if (newMessage.type === "backstage") {
				return {
					backstageMessages: [newMessage, ...state.backstageMessages],
					...(state.activeTab !== 7 && {
						backstageMessagesNotifications: state.backstageMessagesNotifications + 1,
					}),
				};
			}
			return {
				messages: [newMessage, ...state.messages],
				...(state.activeTab !== 1 && { messagesNotifications: state.messagesNotifications + 1 }),
			};
		}),
	addReaction: (reaction) =>
		set((state) => {
			const { messages } = state;
			const { interaction, liveChatId, id, subscriber, createdAt } = reaction;
			const isBackStageMessage = state.backstageMessages.find((m) => m.id === liveChatId);

			let messagesToUse: IMessage[];
			if (isBackStageMessage) {
				messagesToUse = state.backstageMessages;
			} else {
				messagesToUse = messages;
			}
			messagesToUse = messagesToUse?.map((message) => {
				if (interaction.interaction === "") {
					// Handle removing reactions
					message.reactions = message.reactions?.map((r) => ({
						...r,
						subscribers: r.subscribers?.filter((s) => s.id !== id),
					}));
				} else if (message.id === liveChatId) {
					// Handle adding reactions
					const existingReaction = message.reactions?.find(
						(r) => r.reaction === interaction.interaction
					);

					if (existingReaction) {
						if (!existingReaction.subscribers) {
							existingReaction.subscribers = [];
						}
						existingReaction.subscribers.push({
							id,
							subscriber: {
								id: subscriber.id,
								firstName: subscriber.firstName,
								lastName: subscriber.lastName,
								createdAt,
							},
						});
					} else {
						message.reactions = [
							...(message.reactions || []),
							{
								reaction: interaction.interaction,
								subscribers: [
									{
										id,
										subscriber: {
											id: subscriber.id,
											firstName: subscriber.firstName,
											lastName: subscriber.lastName,
											createdAt,
										},
									},
								],
							},
						];
					}
				}

				return message;
			});
			return {
				...(isBackStageMessage
					? { backstageMessages: messagesToUse }
					: { messages: messagesToUse }),
			};
		}),
	messageToReply: null,
	setMessageToReply: (message) => set({ messageToReply: message }),
	questionToEditId: null,
	setQuestionToEditId: (questionToEditId) => set({ questionToEditId }),
	failMessage: "",
	setFailMessage: (failMessage) => set({ failMessage }),
	// notifications
	messagesNotifications: 0,
	questionsNotifications: 0,
	approvedQuestionsNotifications: 0,
	backstageMessagesNotifications: 0,
	setMessagesNotifications: (messagesNotifications) => set({ messagesNotifications }),
	setQuestionsNotifications: (questionsNotifications) => set({ questionsNotifications }),
	setApprovedQuestionsNotifications: (approvedQuestionsNotifications) =>
		set({ approvedQuestionsNotifications }),
	setBackstageMessagesNotifications: (backstageMessagesNotifications) =>
		set({ backstageMessagesNotifications }),
	activeAdvancedSettingsTab: 1,
	setActiveAdvancedSettingsTab: (tab) => set({ activeAdvancedSettingsTab: tab }),
	// this is a form of caching so we don't have to fetch the failed preview links again
	failedPreviewLinksFetch: [],
	setFailedPreviewLinksFetch: (failedPreviewLinksFetch) =>
		set((state) => {
			if (!state.failedPreviewLinksFetch.includes(failedPreviewLinksFetch)) {
				return {
					failedPreviewLinksFetch: [...state.failedPreviewLinksFetch, failedPreviewLinksFetch],
				};
			}
			return state;
		}),
	userAlreadyConnected: false,
	setUserAlreadyConnected: (userAlreadyConnected) => set({ userAlreadyConnected }),
	questionsFilter: "oldest",
	setQuestionsFilter: (questionsFilter) => set({ questionsFilter }),
});
