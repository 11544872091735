import QuestionsIcon from "./../../assets/QuestionsIcon";
import LogoutIcon from "./../../assets/LogoutIcon";
import AdminIcon from "./../../assets/AdminIcon";
import ChatIcon from "./../../assets/ChatIcon";
import Tab from "./Tab";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store/store";
import BackstageIcon from "../../assets/BackstageIcon";
import { useEffect } from "react";
import ModeratorAdvancedSettingsIcon from "../../assets/ModeratorAdvancedSettingsIcon";

interface IProps {
	canComment: boolean | undefined;
	chat: boolean | undefined;
	backstage: boolean | undefined;
}
const Tabs = (props: IProps) => {
	const { t } = useTranslation();
	const liveDetails = useStore((state) => state.live);
	const isCommentValidation = useStore((state) => state.isCommentValidation);
	const user = useStore((state) => state.user);
	const activeTab = useStore((state) => state.activeTab);
	const setActiveTab = useStore((state) => state.setActiveTab);
	const messagesNotifications = useStore((state) => state.messagesNotifications);
	const questionsNotifications = useStore((state) => state.questionsNotifications);
	const backstageMessagesNotifications = useStore((state) => state.backstageMessagesNotifications);
	const approvedQuestionsNotifications = useStore((state) => state.approvedQuestionsNotifications);
	useEffect(() => {
		if (props.canComment || (user?.role && user?.role !== "listener")) {
			setActiveTab(2);
			return;
		}
		if (props.chat) {
			setActiveTab(1);
			return;
		}
		if (props.backstage) {
			setActiveTab(7);
			return;
		}
		setActiveTab(3);
	}, [props.canComment, props.chat, props.backstage]);
	return (
		<div className=" hidden items-center justify-center gap-2 rounded-full  bg-text p-3 lg:flex lg:flex-col">
			{(user?.role && user?.role !== "listener") || props.canComment ? (
				<Tab
					title={t("live_page.questions")}
					icon={<QuestionsIcon active={activeTab === 2} />}
					number={2}
					showLabel={false}
					notifications={approvedQuestionsNotifications}
				/>
			) : null}
			{props.chat ? (
				<Tab
					title={t("live_page.chat")}
					icon={<ChatIcon active={activeTab === 1} />}
					number={1}
					showLabel={false}
					notifications={messagesNotifications}
				/>
			) : null}
			{isCommentValidation && user?.role === "moderator" ? (
				<Tab
					title={t("live_page.pending_questions")}
					icon={<AdminIcon active={activeTab === 5} />}
					number={5}
					showLabel={false}
					notifications={questionsNotifications}
				/>
			) : null}
			{props.backstage ? (
				<Tab
					title="Backstage"
					icon={<BackstageIcon active={activeTab === 7} />}
					number={7}
					showLabel={false}
					notifications={backstageMessagesNotifications}
				/>
			) : null}
			{liveDetails?.canShareFile && liveDetails?.docPublic ? (
				<Tab
					title={t("live_page.advanced_settings")}
					icon={<ModeratorAdvancedSettingsIcon active={activeTab === 8} />}
					number={8}
					showLabel={false}
					notifications={0}
				/>
			) : null}
			<Tab
				title={t("live_page.logout")}
				icon={<LogoutIcon active={activeTab === 6} />}
				number={6}
				showLabel={false}
				notifications={0}
			/>
		</div>
	);
};

export default Tabs;
