import { useEffect } from "react";
import { useStore } from "../../../store/store";
import { IQuestions } from "../useGetQuestions";
import { IEvent, IPong, IReactionEvent, SocketEvents } from "./types";
import QuickLRU from "quick-lru";
import { generateSignedUrl } from "../../../api/generateSignedUrl";
import useSetParticipated from "../../../hooks/useSetParticipated";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IQuestionLike } from "../useLikeQuestion";
import { IMessage } from "../messages/types";
import useSendVerificationEmail from "../useSendVerificationEmail";

const useSocket = (liveId: number | undefined) => {
	const navigate = useNavigate();
	const { liveToken } = useParams();
	const setWebSocket = useStore((state) => state.setWebSocket);
	const setWebSocketError = useStore((state) => state.setWebSocketError);
	const addQuestions = useStore((state) => state.addQuestions);
	const addApprovedQuestions = useStore((state) => state.addApprovedQuestions);
	const setLiveActive = useStore((state) => state.setLiveActive);
	const setStatus = useStore((state) => state.setStatus);
	const deleteQuestion = useStore((state) => state.deleteQuestion);
	const manageQuestion = useStore((state) => state.manageQuestion);
	const deleteQuestionByModerator = useStore((state) => state.deleteQuestionByModerator);
	const addAnswers = useStore((state) => state.addAnswers);
	const addMessages = useStore((state) => state.addMessages);
	const addReaction = useStore((state) => state.addReaction);
	const addQuestionLike = useStore((state) => state.addQuestionLike);
	const user = useStore((state) => state.user);
	const isCommentValidation = useStore((state) => state.isCommentValidation);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const userToken = queryParams.get("token");
	const setParticipated = useSetParticipated(userToken);
	const editQuestion = useStore((state) => state.editQuestion);
	const setBroadcastedQuestion = useStore((state) => state.setBroadcastedQuestion);
	const setUserAlreadyConnected = useStore((state) => state.setUserAlreadyConnected);
	const sendEmailMutation = useSendVerificationEmail(false);
	const setUserSocketId = useStore((state) => state.setUserSocketId);
	const userIpAddress = useStore((state) => state.userIpAddress);

	// Event Handlers
	const handleQuestionEvent = (eventData: IQuestions) => {
		const questionData = eventData;
		if (questionData.type === "answer") {
			addAnswers(questionData);
		}
		if (questionData.type === "question" && isCommentValidation) {
			addQuestions(questionData);
		}
		if (questionData.type === "question" && !isCommentValidation) {
			addApprovedQuestions(questionData);
		}
	};

	const handleEditQuestionEvent = (eventData: IQuestions) => {
		const questionData = eventData;
		editQuestion(questionData);
	};

	const handleChatEvent = (eventData: IMessage) => {
		const chatData = eventData;
		let firstName = "";
		let lastName = "";
		if (chatData.subscriber) {
			if (chatData.subscriber.liveSubscribers && chatData.subscriber.liveSubscribers.length === 1) {
				firstName = chatData.subscriber.liveSubscribers[0].name;
				lastName = chatData.subscriber.liveSubscribers[0].lastName;
			} else {
				firstName = chatData.subscriber.firstName;
				lastName = chatData.subscriber.lastName;
			}
		} else {
			firstName = chatData?.moderator?.first_name;
			lastName = chatData?.moderator?.last_name;
		}
		const chatDataWithReply = {
			...chatData,
			...(chatData.subscriber && {
				subscriber: {
					...chatData.subscriber,
					firstName: firstName,
					lastName: lastName,
				},
			}),
			...(chatData?.moderator && { moderator: chatData?.moderator }),
			...(chatData?.replyMessageId && {
				repliedTo: chatData?.repliedTo,
			}),
		};
		addMessages(chatDataWithReply);
	};

	const handleReactionEvent = (eventData: IReactionEvent) => {
		const reactionData = eventData;
		addReaction(reactionData);
	};

	const handleApproveQuestionEvent = (eventData: IQuestions) => {
		const approvedQuestionData = eventData;
		addApprovedQuestions(approvedQuestionData);
		if (eventData.approved === false) {
			deleteQuestion(eventData);
		}
	};
	const handleDeleteQuestion = (eventData: IQuestions) => {
		const question = eventData;
		deleteQuestionByModerator(question);
	};

	const handleManageQuestion = (evenData: IQuestions) => {
		manageQuestion(evenData);
	};
	const handleLikeQuestion = (eventData: {
		data: {
			questionLike: IQuestionLike | null;
			result: string;
		};
		questionId: number;
	}) => {
		addQuestionLike(eventData);
	};

	const handleStartLiveEvent = () => {
		setLiveActive(1);
		setStatus(0);
		if (userToken && user?.role !== "moderator") {
			setParticipated.mutate();
		}
	};

	const handleEndLiveEvent = () => {
		setStatus(1);
		setLiveActive(0);
	};

	const handleBroadcastQuestion = (eventData: IQuestions) => {
		setBroadcastedQuestion(eventData);
	};
	const handleUserAlreadyConnected = async (e: any) => {
		const url = window.location.href;
		setUserSocketId(e.data.userId);
		setUserAlreadyConnected(true);
		if (user?.id && liveId) {
			sendEmailMutation.mutate({
				subscriberId: user?.id,
				liveId: liveId,
				url: url,
				ipAddress: userIpAddress,
			});
		}
	};

	useEffect(() => {
		let socket: WebSocket | null = null;
		let Mounted = true;
		const join = async () => {
			const lru = new QuickLRU({ maxSize: 50 });
			// @ts-ignore
			let lastPingMs = null;

			if (liveId) {
				let wsUrl = window.process.env.WS_LIVE_URL;
				if (window.process.env.URL_LIVE_INTRANET === window.location.hostname) {
					wsUrl = "//" + window.process.env.URL_LIVE_INTRANET + "/live/ws";
				}
				if (!wsUrl?.includes("wss://")) {
					wsUrl = "wss:" + wsUrl;
				}
				wsUrl += "/?room=" + liveId;
				if (user?.role) {
					wsUrl += "&role=" + user?.role;
				} else {
					wsUrl += "&role=listener";
				}
				if (user?.id) {
					wsUrl += "&subscriberId=" + user.id;
				}

				const signedWsUrl = await generateSignedUrl(new URL(wsUrl));
				socket = new WebSocket(signedWsUrl);
				let rejoined = false;
				const startTime = Date.now();
				const rejoin = async () => {
					if (!rejoined) {
						rejoined = true;
						// Don't try to reconnect too rapidly.
						let timeSinceLastJoin = Date.now() - startTime;
						if (timeSinceLastJoin < 1000) {
							// Less than 1 second elapsed since last join. Pause a bit.
							await new Promise((resolve) => setTimeout(resolve, 1000 - timeSinceLastJoin));
						}
						// OK, reconnect now!
						await join();
					}
				};
				// Handle socket events
				socket.onopen = () => {
					console.info(new Date(), "Socket connected");
					setWebSocketError(false);
					const pingInterval = setInterval(() => {
						try {
							if (socket?.readyState === WebSocket.OPEN) {
								const id = crypto.randomUUID();
								lru.set(id, Date.now());
								// @ts-ignore
								socket.send(JSON.stringify({ type: "ping", data: { id, lastPingMs } }));
							} else {
								clearInterval(pingInterval);
								if (Mounted) {
									rejoin();
								}
							}
						} catch (e) {
							clearInterval(pingInterval);
							if (Mounted) {
								rejoin();
							}
						}
					}, 10000);
				};
				socket.onerror = (error) => {
					setWebSocketError(true);
					console.error("SOCKET ERROR: ", error);
					if (socket?.readyState !== WebSocket.CLOSING && socket?.readyState !== WebSocket.CLOSED) {
						socket?.close();
						console.info(new Date(), "Socket closed");
					}
					if (Mounted) {
						rejoin();
					}
				};
				socket.onmessage = (event) => {
					try {
						const eventData: IEvent = JSON.parse(event.data);
						switch (eventData.type) {
							case SocketEvents.question: {
								handleQuestionEvent(eventData.data as IQuestions);
								break;
							}
							case SocketEvents.chat: {
								handleChatEvent(eventData.data as IMessage);
								break;
							}
							case SocketEvents.reaction: {
								handleReactionEvent(eventData.data as IReactionEvent);
								break;
							}
							case SocketEvents.approveQuestion: {
								handleApproveQuestionEvent(eventData.data as IQuestions);
								break;
							}
							case SocketEvents.editQuestion: {
								handleEditQuestionEvent(eventData.data as IQuestions);
								break;
							}
							case SocketEvents.deleteQuestion: {
								handleDeleteQuestion(eventData.data as IQuestions);
								break;
							}
							case SocketEvents.manageQuestion: {
								handleManageQuestion(eventData.data as IQuestions);
								break;
							}
							case SocketEvents.likeQuestion: {
								handleLikeQuestion(
									eventData.data as {
										data: {
											questionLike: IQuestionLike | null;
											result: string;
										};
										questionId: number;
									}
								);
								break;
							}
							case SocketEvents.liveStarted: {
								handleStartLiveEvent();
								break;
							}
							case SocketEvents.liveEnded: {
								handleEndLiveEvent();
								break;
							}
							case SocketEvents.broadcastQuestion: {
								handleBroadcastQuestion(eventData.data as IQuestions);
								break;
							}
							case SocketEvents.userAlreadyConnected: {
								handleUserAlreadyConnected(eventData);
								break;
							}
							case SocketEvents.userVerified: {
								try {
									socket?.close();
									navigate(`/${liveToken}`);
								} catch (e) {}
								break;
							}
							case SocketEvents.error: {
								console.info(new Date(), "ERROR:", eventData.data);
								rejoin();
								break;
							}
							case SocketEvents.pong: {
								const data = eventData.data as IPong;
								const ping = lru.get(data.id);
								if (ping) {
									// @ts-ignore
									lastPingMs = Date.now() - ping;
								}
								break;
							}

							default:
								break;
						}
					} catch (e) {
						console.error("Error parsing socket message", e);
						socket?.send(JSON.stringify({ type: "error", data: "Error parsing socket message" }));
					}
				};

				socket.onclose = () => {
					setWebSocketError(true);
					console.info(new Date(), "Socket disconnected");
					if (Mounted) {
						rejoin();
					}
				};

				// Set the new socket in  state
				setWebSocket(socket);
			}
		};
		join();
		// Cleanup function
		return () => {
			Mounted = false;
			if (socket?.readyState !== WebSocket.CLOSING && socket?.readyState !== WebSocket.CLOSED) {
				socket?.close();
				console.info(new Date(), "Socket closed");
			}
		};
	}, [liveId]);
};

export default useSocket;
