import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import useGetLiveDetails from "./hooks/useGetLiveDetails";
import SignupForm from "./SignupForm/SignupForm";
import Loader from "../components/Loader/Loader";
import { useEffect, useState } from "react";
import { useStore } from "../store/store";
import RequirePassword from "../components/RequirePassword";
import { isAxiosError } from "axios";
import useCheckProtectedLive from "./hooks/useCheckProtectedLive";
import Description from "./Description";
import Footer from "./Footer";
import LiveDate from "./LiveDate";
import LiveTitle from "./LiveTitle";
import RegisterBackgroundImage from "./../assets/RegisterBackground.png";
import ModeratorForm from "../AuthenticationPage/ModeratorForm";
import { twMerge } from "tailwind-merge";
import useGetUserDetails from "../AuthenticationPage/hooks/useGetUserDetails";
import TurnstileContainer from "../components/Captcha/TurnstileContainer";
import { useCookies } from "react-cookie";
const SignupPage = () => {
	const { liveToken } = useParams();
	const location = useLocation();
	const [cloudflareToken, setCloudflareToken] = useState<string | null>(null);
	const [cookies, setCookie] = useCookies(["cloudflareToken"]);
	const liveData = useGetLiveDetails(liveToken);
	const data = liveData.data?.live;
	const setLiveId = useStore((state) => state.setLiveId);
	const setLive = useStore((state) => state.setLive);
	const isModerator = location.pathname.includes("/moderator/");
	const isJournalist = location.pathname.includes("/journalist/");
	const showPasswordModal = useStore((state) => state.showPasswordModal);
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get("token");
	const userDetails = useGetUserDetails(token, isModerator ? "moderator" : "subscriber");
	const navigate = useNavigate();

	useEffect(() => {
		const tokenFromCookie = cookies?.cloudflareToken;
		if (tokenFromCookie) {
			setCloudflareToken(tokenFromCookie);
		}
	}, [cookies]);

	useEffect(() => {
		if (userDetails.data) {
			if (isJournalist) {
				if (userDetails.data.role !== "journalist") {
					navigate(`/journalist/${liveToken}`);
				} else {
					navigate(`/live/${liveToken}?token=${token}`);
				}
			} else if (isModerator) {
				if (userDetails.data.role !== "moderator") {
					navigate(`/moderator/${liveToken}`);
				} else {
					navigate(`/live-participant/${liveToken}?token=${token}`);
				}
			} else if (userDetails.data.role === "listener") {
				navigate(`/live/${liveToken}?token=${token}`);
			} else {
				navigate(`/${liveToken}`);
			}
		} else if (userDetails.error) {
			if (isJournalist) {
				navigate(`/journalist/${liveToken}`);
			} else if (isModerator) {
				navigate(`/moderator/${liveToken}`);
			} else {
				navigate(`/${liveToken}`);
			}
		}
	}, [userDetails.data, userDetails.error]);

	useEffect(() => {
		if (data?.id) {
			setLiveId(data?.id);
			setLive(data);
		}
	}, [liveData.data, data?.id]);

	useCheckProtectedLive(liveData);

	if (liveData.isLoading) {
		return (
			<div className="flex h-screen items-center justify-center">
				<Loader />
			</div>
		);
	}

	if (
		liveData.isError &&
		liveData.error &&
		isAxiosError(liveData.error) &&
		liveData.error.response?.status === 404
	) {
		return <Navigate to={`/404`} />;
	}
	if (liveData.data?.live.subscription === false && !isModerator && !isJournalist) {
		return <Navigate to={`/live/${liveToken}`} />;
	}
	const handleSuccess = (token: string) => {
		setCloudflareToken(token);
		setCookie("cloudflareToken", token, { maxAge: 86400 });
	};

	if (!cloudflareToken) {
	return <TurnstileContainer onSuccess={handleSuccess} />;
	}

	return (
		<main
			className={twMerge(
				"flex  min-h-screen flex-col items-center justify-center bg-rte text-white",
				isModerator || isJournalist ? "bg-text" : "bg-rte"
			)}
			style={{
				backgroundImage: `url(${RegisterBackgroundImage})`,
			}}
		>
			{showPasswordModal ? <RequirePassword /> : null}
			<div className="flex  h-full w-full flex-1 flex-col flex-wrap items-center justify-center gap-2  p-3">
				<div
					className={twMerge(
						"flex h-full  w-full flex-col items-center justify-start gap-3 xl:h-[33rem] xl:flex-row",
						isModerator && "xl:h-[29rem]"
					)}
				>
					<div className=" flex h-full w-full  flex-col items-center xl:w-3/5">
						<LiveTitle isJournalist={isJournalist} isModerator={isModerator} title={data?.title} />
						<div className=" flex w-full flex-col gap-3 px-5 pt-5 text-center text-base font-semibold italic lg:text-xl xl:max-w-[80%]">
							<LiveDate
								isError={liveData.isError}
								endDate={data?.date.EndDate}
								startDate={data?.date.StartDate}
							/>
							<Description description={data?.description} />
						</div>
					</div>
					{isModerator ? (
						<ModeratorForm liveToken={liveToken} />
					) : (
						<SignupForm isJournalist={isJournalist} />
					)}
				</div>
			</div>
			<Footer />
		</main>
	);
};

export default SignupPage;
